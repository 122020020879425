<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <b-row>
              <b-col md="4" class="text-left">
                <strong> PAGAR PEDIDO #{{order.code }}</strong>
              </b-col>
            </b-row>
          </CCardHeader>
          <CCardBody>
         
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
           

                <b-col md="2">
                  <b-form-group label="Mesa :">
                    <b-form-input disabled class="text-left" :value="order.room_name+' | '+ order.table_name" type="text"></b-form-input>
                  </b-form-group>
                </b-col>

                 <b-col md="2">
                  <b-form-group label="Fecha :">
                    <b-form-input disabled class="text-center" type="text" v-model="order.date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Moso :">
                    <b-form-input disabled class="text-left" type="text" v-model="order.moso_name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="F. Pago :">
                    <b-form-select @change="TotalPayment" v-model="order.way_to_pay" :options="way_to_pay"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda :">
                    <b-form-select @change="TotalOrder" v-model="order.coin" :options="coin"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label>Cliente: <span @click="ShowModalClients" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Observación :">
                    <b-form-input class="text-left" type="text" v-model="order.observation"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="5%" class="text-center">#</th>
                          <th width="60%" class="text-center">Descripción</th>
                          <th width="10%" class="text-center">Cantidad</th>
                          <th width="15%" class="text-center">P. Unit</th>
                          <th width="10%" class="text-center">P. Total</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in order.order_detail" :key="it">
                        <tr>
                          <td class="text-center">{{ it + 1 }}</td>
                          <td class="text-left"> {{ item.name }}</td>
                          <td class="text-right">{{ item.quantity }}</td>
                          <td class="text-right">
                            <b-form-input type="number" @change="UpdateOrderDetail(it)" class="text-right" step="any" v-model="item.unit_price"></b-form-input>
                          </td>
                          <td class="text-right">{{ item.total_price }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <small v-if="errors.order_detail" class="form-text text-danger" >Ingrese productos</small>
                </b-col>
                <b-col  md="5">
                  <div v-if="order.way_to_pay == '01-000'" class="table-responsive">
                    <table  class="table table-hover table-bordered">
                      <thead>
                        <tr>
                            <td width="35%" class="align-middle text-center">Caja</td>
                            <td width="40%" class="align-middle text-center">M. Pago</td>
                            <td width="20%" class="align-middle text-center">Monto</td>
                            <td width="5%" class="align-middle text-center"></td>
                        </tr>
                      </thead>
                      <thead>
                        <tr v-for="(item, it) in payments" :key="it" >
                            <td class="align-middle">
                                <b-form-input disabled v-model="item.box"></b-form-input>
                            </td>
                            <td class="align-middle">
                                <b-form-select v-model="item.payment_method" :options="payment_method"></b-form-select>
                            </td>
                            <td class="align-middle">
                              <b-form-input class="text-right" @change="UpdateTotalPayment" size="sm" type="number" step="any" v-model="item.total"></b-form-input>
                            </td>
                            <td class="align-middle text-center">
                              <b-button @click="DeletePayment(it)" type="button" class="p-1" variant="danger"><i class="fas fa-trash"></i></b-button>
                            </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-if="order.way_to_pay == '01-000'" class="w-100">
                    <b-link @click="AddPayment"><i class="fas fa-plus"></i> Agregar pago</b-link>
                    <small v-if="errors.payments" class="form-text text-danger" >Ingrese un pago</small>
                  </div>


                  <div v-if="order.way_to_pay != '01-000'" class="table-responsive">
                    <table  class="table table-hover table-bordered">
                      <thead>
                        <tr>
                            <td width="35%" class="align-middle text-center">Caja</td>
                            <td width="40%" class="align-middle text-center">M. Pago</td>
                            <td width="20%" class="align-middle text-center">Total</td>
                        </tr>
                      </thead>
                      <thead>
                        <tr >
                            <td class="align-middle">
                                <b-form-input disabled v-model="box"></b-form-input>
                            </td>
                            <td class="align-middle">
                                <b-form-input disabled value="CRÉDITO"></b-form-input>
                            </td>
                            <td class="align-middle">
                                <b-form-input class="text-right" disabled v-model="order.total"></b-form-input>
                            </td>
                         
                        </tr>
                      </thead>
                    </table>
                  </div>

                  
                </b-col>
                <b-col md="4">
                </b-col>
                <b-col md="3">
                   <div class="table-responsive">
                    <table  class="table   table-hover table-lg mt-lg mb-0">
                      <tbody>
                        <tr>
                            <td width="60%" class="align-middle text-right text-total">SUBTOTAL:</td>
                            <td width="40%" class="align-middle text-right text-total">
                              <b-form-input class="text-right" size="sm" disabled type="number" v-model="order.subtotal" step="any"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">RESERVA:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input class="text-right" disabled @change="TotalOrder" size="sm" type="number" v-model="order.reservation" step="any"></b-form-input>
                           </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">DESCUENTO:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input class="text-right" @change="TotalOrder" size="sm" type="number" v-model="order.discount" step="any"></b-form-input>
                           </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">TOTAL:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input class="text-right" size="sm" disabled type="number" v-model="order.total" step="any"></b-form-input>
                            </td>
                        </tr>
                         <tr>
                            <td colspan="2" class="align-middle text-center text-total">{{order.number_to_letters}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="align-middle text-center"><small  v-if="errors.total"  class="form-text text-danger">Ingrese un monto</small></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
                  

                <b-col md="5"></b-col>
    
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>


              </b-row>
            </b-form>
           
              
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <!-- <Keypress key-event="keyup" :key-code="115" @success="Validate" /> -->
  </div>
</template>
<style scoped>
.btn-letter{
  height: 4rem;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] { -moz-appearance:textfield; }

</style>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";
import EventBus from '@/assets/js/EventBus';

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import LoadingComponent from './../pages/Loading'
import OrderDetail from './components/OrderDetail'
import ModalClients from './../components/ModalClient'
import converter from "@/assets/js/NumberToLetters";
export default {
  name: "UsuarioAdd",
  props: ["id_order"],
  components:{
    Keypress: () => import('vue-keypress'),
    LoadingComponent,
    vSelect,
    OrderDetail,
    ModalClients,
  },
  data() {
    return {
      isLoading: false,
      module: 'Box',
      role:2,
      order:{
        id_order:'',
        id_table:'',
        id_room:'',
        id_warehouse:'',
        id_client:'',
        room_name:'',
        table_name:'',
        moso_name:'',
        id_reservation:'',
        reservation:'',
        way_to_pay:'01-000',
        coin:'PEN',
        code:'',
        date:'',
        observation:'',
        subtotal:'',
        delivery:'',
        discount:'',
        total:'',
        number_to_letters:'',
        state:'',
        order_detail:[],
      },
      clients: [],
      client: {id:1,full_name:'CLIENTES VARIOS - 00000000'},
      payments:[],
      boxes:[],
      way_to_pay: [
          {value :"01-000", text :'CONTADO'},
          {value :"03-7", text :'CREDITO - 7 DIAS'},
          {value :"03-15", text :'CREDITO - 15 DIAS'},
          {value :"03-30", text :'CREDITO - 30 DIAS'},
          {value :"03-45", text :'CREDITO - 45 DIAS'},
          {value :"03-60", text :'CREDITO - 60 DIAS'},
          {value :"03-75", text :'CREDITO - 75 DIAS'},
          {value :"03-90", text :'CREDITO - 90 DIAS'},
      ],
      payment_method: [
          {value :"001", text :'DEPÓSITO EN CUENTA'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"004", text :'ORDEN DE PAGO'},
          {value :"005", text :'TARJETA DE DÉBITO'},
          {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"007", text :'CHEQUES CON LA CLÁUSULA DE "NO NEGOCIABLE"'},
          {value :"008", text :'EFECTIVO'},
          {value :"101", text :'TRANSFERENCIAS - COMERCIO EXTERIOR'},
          {value :"102", text :'CHEQUES BANCARIOS - COMERCIO EXTERIOR'},
          {value :"999", text :'CRÉDITO'},
      ],
      coin:[
        {value :"PEN", text :'Soles'},
        {value :"USD", text :'Dólares'},
        {value :"CLP", text :'Pesos Chilenos'},
      ],
      box:'',
      id_box:'',
      errors: {
        id_client: false,
        way_to_pay:false,
        date:false,
        total:false,
        payments:false,
        order_detail:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.box = '';
    this.id_box = '';
    // this.payments = [{id_box:'',box:'', payment_method:'008', total: (0).toFixed(2)}];
    this.GetBoxByUser();
    // this.ViewOrder();
  
    
  },
  methods: {
    ViewOrder,
    SearchClients,
    ShowModalClients,
    
    GetBoxByUser,
    AddPayment,
    DeletePayment,
    TotalPayment,
    UpdateTotalPayment,

    UpdateOrderDetail,
    TotalOrder,
    
    Validate,
    PaymentOrder,

    ...mapActions('Order',['mLoadAddOrderDetail','mLoadResetOrderDetail']),
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function UpdateTotalPayment() {
  for (let index = 0; index < this.payments.length; index++) {
    const element = this.payments[index];
    element.total = parseFloat(element.total).toFixed(2);
  }
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function GetBoxByUser() {
  let me = this;
  let url = me.url_base + "box/get-box-by-user/"+me.user.id_user;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.box = response.data.result.code + " | " + response.data.result.user;
      me.id_box = response.data.result.id_box;
      me.payments = [];
      me.payments = [{id_box:me.id_box,box:me.box, payment_method:'008', total: (0).toFixed(2)}];
      me.ViewOrder();
    } else {
      me.id_box = '';
      me.box = '';
      me.payments = [];
      me.ViewOrder();
    }
  })
  .catch((error) => {
  });
}

function ShowModalClients() {
  EventBus.$emit('ModalClientsShow');
}

function ViewOrder() {
  
  let id_order = je.decrypt(this.id_order);
  let me = this;
  let url = this.url_base + "order/view/"+id_order;
  me.mLoadResetOrderDetail();
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      
      if (response.data.status == 200) {
        me.order.id_order = response.data.result.order.id_order;
        me.order.id_table = response.data.result.order.id_table;
        me.order.id_room = response.data.result.order.id_room;
        me.order.id_warehouse = response.data.result.order.id_warehouse;
        me.order.id_client = response.data.result.order.id_client;
        me.order.room_name = response.data.result.order.room_name;
        me.order.table_name = response.data.result.order.table_name;
        me.order.moso_name = response.data.result.order.moso_name;
        me.order.code = response.data.result.order.code;
        me.order.way_to_pay = response.data.result.order.way_to_pay;
        me.order.date = response.data.result.order.date;
        me.order.observation = response.data.result.order.observation;
        me.order.subtotal = response.data.result.order.subtotal;
        me.order.reservation = response.data.result.order.reservation;
        me.order.delivery = response.data.result.order.delivery;
        me.order.discount = response.data.result.order.discount;
        me.order.total = response.data.result.order.total;
        me.order.number_to_letters = response.data.result.order.number_to_letters;
        me.order.state = response.data.result.order.state;

        me.order.order_detail = response.data.result.order_detail;
        me.client = {id:response.data.result.order.id_client,full_name:response.data.result.order.client_name+" - "+response.data.result.order.client_document_number};

        me.TotalOrder();


      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}



function AddPayment() {
  this.payments.push(
    {id_box:this.id_box,box:this.box, payment_method:'008', total: (0).toFixed(2)}
  )
  this.TotalPayment();
}

function TotalPayment() {
  let total = this.order.total;
  let payment_method = this.order.way_to_pay.split("-");
  if (payment_method[0] == "01") {
    if (this.payments.length == 0) {
      this.AddPayment();
    }else{
      let total_payment = parseFloat(total) / parseFloat(this.payments.length);
      for (let index = 0; index < this.payments.length; index++) {
        this.payments[index].id_box = this.id_box;
        this.payments[index].box = this.box;
        this.payments[index].total = parseFloat(total_payment).toFixed(2);
      }
    }
  }else{
    this.payments = [];
  }
}


function DeletePayment(index) {
   this.payments.splice(index, 1);
   this.TotalPayment();
}

function UpdateOrderDetail(index) {
  this.order.order_detail[index].quantity = this.order.order_detail[index].quantity.length == 0 ? '1.00':parseFloat(this.order.order_detail[index].quantity).toFixed(2);
  this.order.order_detail[index].unit_price = this.order.order_detail[index].unit_price.length == 0 ? '0.00':parseFloat(this.order.order_detail[index].unit_price).toFixed(2);

  this.order.order_detail[index].total_price = parseFloat(this.order.order_detail[index].quantity) * parseFloat(this.order.order_detail[index].unit_price);
  this.order.order_detail[index].total_price = parseFloat(this.order.order_detail[index].total_price).toFixed(2);

  this.TotalOrder();
}

function TotalOrder() {
  
  let detail = this.order.order_detail;
  let subtotal = 0;
  

  for (let index = 0; index < detail.length; index++) {
      subtotal += parseFloat(detail[index].total_price);
  }

  this.order.subtotal = subtotal;
  this.order.reservation = this.order.reservation.length == 0 ? 0 : parseFloat(this.order.reservation).toFixed(2);
  this.order.discount = this.order.discount.length == 0 ? 0 : parseFloat(this.order.discount).toFixed(2);
  this.order.total = parseFloat(this.order.subtotal) - parseFloat(this.order.discount) - parseFloat(this.order.reservation);

  this.order.subtotal = parseFloat(this.order.subtotal).toFixed(2);
  this.order.discount = parseFloat(this.order.discount).toFixed(2);
  this.order.total = parseFloat(this.order.total).toFixed(2);

  //total en letras
  if (parseFloat(this.order.total) > 0) {
    converter.NumberToLettersApi(this.order.total,this.order.coin).then((data) => {
      if (data.status == 200) {
          this.order.number_to_letters = 'SON : '+ data.result;
      }else{
          this.order.number_to_letters =  "SON : " +converter.NumberToLetters(total.total);
      } 
    });
  }
  

  this.TotalPayment();

}

function Validate() {
  
  this.validate = false;
  let payment_method = this.order.way_to_pay.split("-");

  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_box = this.id_box.length == 0 ? true : false;
  this.errors.date = this.order.date.length == 0 ? true : false;
  this.errors.way_to_pay = this.order.way_to_pay.length == 0 ? true : false;
  this.errors.order_detail = this.order.order_detail.length == 0 ? true : false;
  this.errors.total = this.order.total.length == 0 || parseFloat(this.order.total) == 0 ? true : false;
  if (payment_method[0] == "01") {
    this.errors.payments = this.payments.length == 0 ? true : false;
  }


  if (this.errors.id_client == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.order_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.total == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.payments == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_box == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que tenga una caja abierta', timer: 2000,}); return false;}else{ this.validate = false; }
  
  let total = 0;
  if (payment_method[0] == "01") {
    for (let index = 0; index < this.payments.length; index++) {
      const element = this.payments[index];
      if (element.payment_method.length == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que los pagos cuenten con un metodo de pago', timer: 2000,}); return false;
      }
      if (element.total.length == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que el total de los pagos sean mayores a 0', timer: 2000,}); return false;
      }
      if (parseFloat(element.total) <= 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que el total de los pagos sean mayores a 0', timer: 2000,}); return false;
      }
      total += parseFloat(element.total);
    }

    let balance_payment = parseFloat(this.order.total) - parseFloat(total);
    if (balance_payment < 0 || balance_payment > 0.15) {
      Swal.fire({ icon: 'warning', text: 'Verifique que los pagos coincidan con el total del comprobante', timer: 4000,}); 
      return false;
    }
  }

  
  let me = this;
  Swal.fire({
    title: 'Esta seguro de guardar el pago?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.PaymentOrder();
    }
  })
}

function PaymentOrder() {
  let me = this;
  me.isLoading = true;
  let url = me.url_base + "order/payment";
  me.order.id_client = me.client.id;
  me.order.id_user = me.user.id_user;
  me.order.id_box = me.id_box;
  me.order.payments = me.payments;
  let data = me.order;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.$router.push({
          name: "SaleAddOrder",
          params: { id_order: je.encrypt(me.order.id_order) },
        });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 2000})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 2000})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 2000})
      me.isLoading = false;
    });
}


</script>
