<template>
  <div class="table-responsive mt-3 ">
    <table class="table table-hover table-bordered">
      <thead>
        <tr>
          <th width="3%" class="text-center">#</th>
          <th width="62%" class="text-center">Descripción</th>
          <th width="13%" class="text-center">Cantidad</th>
          <th width="15%" class="text-center">P. Unit</th>
          <th width="8%" class="text-center"></th>
   
        </tr>
      </thead>
      <tbody v-for="(item, it) in order_detail" :key="it">
        <tr>
          <td class="text-center">{{ it + 1 }}</td>
          <td class="text-left">{{ item.name }}</td>
          <td class="text-right">{{ item.quantity }}</td>
          <td class="text-right">
            <b-form-input size="sm" readonly @click="ModalPriceShow(it)" type="number" class="text-right" step="any" v-model="item.unit_price"></b-form-input>
          </td>
           <td class="text-right">
          <b-form-checkbox  size="sm" v-model="item.state" value="0" unchecked-value="1" ></b-form-checkbox>    
        </td>
        </tr>
        <tr v-if="item.observation.length > 0"  >
          <td colspan="7" class="text-left">
            <span v-for="(item1, it1) in item.observation" :key="it1">{{ item1 + ', '}}</span>
          </td>
        </tr>
       

      </tbody>
    </table>



  <b-modal size="xl" hide-footer v-model="modalEditPrice" class="w-100" title="Modificar Precio">
      <b-form id="Form" @submit.prevent="EditPrice">
          <b-row>
          
            <b-col md="4">
              <b-form-group label="Producto:">
                <b-form-input disabled v-model="new_order_detail.name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Cantidad:">
                <b-form-input disabled class="text-right" type="number" step="any" v-model="new_order_detail.quantity"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="P. Unit:">
                <b-form-input class="text-right" @change="CalculatePrice" type="number" step="any" v-model="new_order_detail.unit_price"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="P. Total:">
                <b-form-input disabled class="text-right" type="number" step="any" v-model="new_order_detail.total_price"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Clave Maestra:">
                <b-form-input type="password" v-model="new_order_detail.master_key"></b-form-input>
              </b-form-group>
            </b-col>


            <b-col md="5"></b-col>
            <b-col md="2">
              <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
            </b-col>
          </b-row>
        </b-form>
  </b-modal>



  </div>
</template>
<style scoped>

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';

export default {
  name: "InputDetail",
  data() {
    return {
      module: 'Order',
      role:2,
      modalEditPrice: false,
      new_order_detail: {
        id_order_detail:'',
        name: '',
        quantity: 0,
        unit_price: 0,
        total_price: 0,
        master_key:'',
      },
      
    };
  },
  mounted() {

  
  },
  methods: {
    EditDetail,
    DeleteDetail,

    ModalPriceShow,
    EditPrice,
    CalculatePrice,
    
    UpdateOrderDetailInputObservation,
    ...mapActions('Order',['mLoadEditOrderDetail']),
    ...mapActions('Order',['mLoadDeleteOrderDetail']),
    
  },
  computed: {
    ...mapState('Order',['order_detail']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ModalPriceShow(index) {
    this.modalEditPrice = true;
    this.new_order_detail.index = index;
    this.new_order_detail.id_order_detail = this.order_detail[index].id_order_detail;
    this.new_order_detail.name = this.order_detail[index].name;
    this.new_order_detail.quantity = this.order_detail[index].quantity;
    this.new_order_detail.unit_price = this.order_detail[index].unit_price;
    this.new_order_detail.total_price = this.order_detail[index].total_price;
}

function UpdateOrderDetailInputObservation(index) {
    this.order_detail[index].input_observation = this.order_detail[index].input_observation == 1 ? 0:1;
}

function EditDetail(index) {
  let name = this.order_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha modificado el pedido ' + name, type: 'warn'})
  this.mLoadEditOrderDetail(index)
  
}

function DeleteDetail(index) {
  let name = this.order_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha eliminado el pedido ' + name, type: 'warn'})
  this.mLoadDeleteOrderDetail(index)
}

function CalculatePrice() {

  this.new_order_detail.unit_price = this.new_order_detail.unit_price.length == 0 ? 0 : this.new_order_detail.unit_price;
  this.new_order_detail.total_price = parseFloat(this.new_order_detail.unit_price) * parseFloat(this.new_order_detail.quantity);

  this.new_order_detail.unit_price = parseFloat(this.new_order_detail.unit_price).toFixed(2);
  this.new_order_detail.total_price = parseFloat(this.new_order_detail.total_price).toFixed(2);

}

function EditPrice() {
  if (this.new_order_detail.unit_price.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese un precio ', type: 'warn'})
    return false;
  }

  if (this.new_order_detail.master_key.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese una clave maestra ', type: 'warn'})
    return false;
  }

  let me = this;
  let url = me.url_base + "order/edit-price-detail";
  let data = this.new_order_detail;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.modalEditPrice = false;
        me.new_order_detail.master_key = '';
        EventBus.$emit('RefreshOrder',data);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      console.log(error);
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });

}
</script>
